import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Resume = ({
  data: {
    allContentfulResumeItem: { edges }
  }
}) => (
  <Layout>
    <SEO title="Resume" ogUrl="/resume" />

    <section className="wrapper style2 alt">
      <div className="inner">
        <header className="major">
          <h2>Résumé</h2>
        </header>

        {edges.map(edge => {
          const { node } = edge;
          const {
            jobTitle,
            publication,
            startDate,
            endDate,
            currentlyWorkHere
          } = node;

          return (
            <div key={edge.node.id}>
              <header>
                <h3>
                  {publication}
                  {jobTitle ? ` - ${jobTitle}` : null}
                </h3>
                <p>
                  {startDate} - {currentlyWorkHere ? 'Present' : endDate}
                </p>
              </header>

              {documentToReactComponents(node.description.json)}

              <hr />
            </div>
          );
        })}
      </div>
    </section>
  </Layout>
);

Resume.propTypes = {
  data: PropTypes.object.isRequired
};

export default Resume;

export const pageQuery = graphql`
  query {
    allContentfulResumeItem(sort: { order: DESC, fields: startDate }) {
      edges {
        node {
          startDate(formatString: "MM.YY")
          endDate(formatString: "MM.YY")
          jobTitle
          publication
          description {
            json
          }
          currentlyWorkHere
        }
      }
    }
  }
`;
